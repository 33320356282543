import { useMutation } from '@tanstack/react-query'
import { updateUserProfile } from '@Requests/user.api'

export const useUpdateUserProfile = ({ handleOnSuccess, handleOnError }: IMutationParameters) => {
	const mutation = useMutation({
		mutationFn: updateUserProfile,
		onSuccess: (response) => {
			handleOnSuccess?.(response.data)
		},
		onError: (err) => {
			handleOnError && handleOnError(err)
		},
	})
	return {
		mutation,
	}
}
