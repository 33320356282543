import { Configuration, RedirectRequest } from '@azure/msal-browser'
const ua = window.navigator.userAgent
const msie = ua.indexOf('MSIE ')
const msie11 = ua.indexOf('Trident/')
const msedge = ua.indexOf('Edge/')
const firefox = ua.indexOf('Firefox')
const isIE = msie > 0 || msie11 > 0
const isEdge = msedge > 0
const isFirefox = firefox > 0

const port = window.location.port ? `:${window.location.port}` : ''
const rootUrl = `${window.location.protocol}//${window.location.hostname}${port}`

export const msalConfig: Configuration = {
	auth: {
		clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? '',
		authority: process.env.REACT_APP_MSAL_AUTHORITY,
		redirectUri: rootUrl,
		navigateToLoginRequestUrl: true,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: isIE || isEdge || isFirefox,
	},
}

export const loginRequest: RedirectRequest = {
	scopes: [process.env.REACT_APP_MSAL_LOGIN_REQUEST_SCOPE ?? ''],
}

export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
	userProfileSelectProperties: 'id,displayName,mail,userPrincipalName,jobTitle,givenName,officeLocation,department',
}

export const graphScope: RedirectRequest = {
	scopes: ['User.Read'],
}
