import { ERROR_MESSAGE, STRING_ERROR_CODE } from '@Constants/errors'
import { PUBLIC_ROUTES, ROUTE_TITLE } from '@Constants/routes'
import { useUpdateUserProfile } from '@Data-Hooks/Mutations/Consent/useUserProfile'
import { useGetUserLoginConsent } from '@Data-Hooks/Queries/Consent/useConsent'
import { useMsalAuth } from '@Hooks/useMsalAuth'
import { useNetwork } from '@Hooks/useNetwork'
import DKMGlobalError from '@Shared/DKMGlobalError'
import FullScreenLoader from '@Shared/Loader/FullScreenLoader'
import NoNetwork from '@Shared/NoNetwork'
import { useSnackbar } from '@Store/snackbar'
import { useUser } from '@Store/user'
import React, { lazy, Suspense, useEffect, useMemo } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
const PublicRoutes = lazy(() => import('./PublicRoutes'))
const PrivateRoutes = lazy(() => import('./PrivateRoutes'))
const LoginConsentRoutes = lazy(() => import('./LoginConsentRoutes'))

const AppRoutes: React.FC = () => {
	const { isAuthenticated, isLoading } = useMsalAuth()
	const { user, updateUser, updateExternalKDQuery } = useUser()
	const { openToast } = useSnackbar()
	const navigate = useNavigate()
	const { isOnline } = useNetwork()
	const location = useLocation()
	const {
		data: userLoginConsent = { status: false, message: '', is_profile_updated: null },
		isLoading: isLoadingUserConsent,
		isError: isUserConsentError,
	} = useGetUserLoginConsent(isAuthenticated)

	// Update user profile details if not yet done
	const { mutation: updateUserProfile } = useUpdateUserProfile({
		handleOnError: () => {
			openToast('warning', ERROR_MESSAGE[STRING_ERROR_CODE.UPDATE_USER_PROFILE])
		},
	})

	const appMetaHeadTitle = useMemo(() => {
		const title = ROUTE_TITLE[`/${window.location.pathname.split('/')[1]}`]
		return title ? `Navigator - ${title}` : 'Navigator'
	}, [window.location.pathname])

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search)
		const query = queryParams.get('query')
		if (query) {
			updateExternalKDQuery(query)
		}
	}, [location.search])

	useEffect(() => {
		updateUser({ ...user, consent: userLoginConsent.status })
	}, [userLoginConsent.status])

	useEffect(() => {
		if(userLoginConsent.is_profile_updated === false) {
			updateUserProfile.mutate({
				role: user.role,
				department: user.department,
				office_location: user.officeLocation,
			})
		}
	}, [userLoginConsent.is_profile_updated])

	useEffect(() => {
		if (isUserConsentError) {
			navigate(PUBLIC_ROUTES.root)
		}
	}, [isUserConsentError])

	if (isUserConsentError) {
		return <DKMGlobalError />
	}

	if (isAuthenticated && !isOnline) {
		return <NoNetwork />
	}

	if (isLoading || isLoadingUserConsent) {
		return <FullScreenLoader />
	}

	return (
		<>
			<Helmet>
				<title>{appMetaHeadTitle}</title>
			</Helmet>
			<Suspense fallback={<FullScreenLoader />}>
				{!isAuthenticated && <PublicRoutes />}
				{isAuthenticated && !userLoginConsent.status && <LoginConsentRoutes />}
				{isAuthenticated && userLoginConsent.status && <PrivateRoutes />}
			</Suspense>
		</>
	)
}

export default AppRoutes
