import { useQuery } from '@tanstack/react-query'
import { QUERY_KEY } from '@Constants/commons'
import { getUserLoginConsent } from '@Requests/user.api'

export const useGetUserLoginConsent = (enabled: boolean) => {
	return useQuery({
		queryKey: [QUERY_KEY.GET_USER_LOGIN_CONSENT],
		queryFn: getUserLoginConsent,
		select: ({ data }) => data,
		enabled,
	})
}
