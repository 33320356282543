import { InteractionRequiredAuthError, InteractionStatus, RedirectRequest, SilentRequest } from '@azure/msal-browser'
import { useAccount, useMsal } from '@azure/msal-react'
import { graphScope, loginRequest } from '@Config/authConfig'
import { SECURE_STORAGE_KEY } from '@Constants/commons'
import { useGraphUserDetails } from '@Data-Hooks/Queries/Graph/useGraphUserDetails'
import { useGraphUserMetadata } from '@Data-Hooks/Queries/Graph/useGraphUserMetadata'
import { useUser } from '@Store/user'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import secureLocalStorage from 'react-secure-storage'
import { logAction, setUserToData } from '@Libs/utilities'

export const useMsalAuth = () => {
	const { instance, accounts, inProgress } = useMsal()
	const account = useAccount(accounts[0] || null)
	const [isAuthenticated, setIsAuthenticated] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [graphToken, setGraphToken] = useState<string | null>(null)
	const { user, updateUser } = useUser()
	const { data: userDetails, isError: isUserDetailsError, refetch: refetchUserDetails } = useGraphUserDetails(graphToken)
	const { data: userMetadata, isError: isMetadataError, refetch: refetchUserMetadata } = useGraphUserMetadata(graphToken)

	const setAxiosInterceptors = useCallback((token: string) => {
		axios.interceptors.request.use(
			(config) => {
				secureLocalStorage.setItem(SECURE_STORAGE_KEY.JWT, token)
				if (!config.headers.Authorization) {
					try {
						config.headers.Authorization = `Bearer ${token}`
					} catch (error) {
						return Promise.reject(new Error('Failed to set Authorization header'))
					}
				}
				return config
			},
			(error) => {
				return Promise.reject(error instanceof Error ? error : new Error(String(error)))
			}
		)
	}, [])

	useEffect(() => {
		if (!isUserDetailsError && userDetails) {
			setUserToData({email: userDetails.mail || userDetails.userPrincipalName, id: userDetails.id, name: userDetails.displayName})
			updateUser({
				...user,
				id: userDetails.id,
				name: userDetails.displayName,
				email: userDetails.mail || userDetails.userPrincipalName,
				photo: isMetadataError ? null : userMetadata,
				role: userDetails.jobTitle ?? '',
				firstName: userDetails.givenName,
				department: userDetails.department ?? '',
				officeLocation: userDetails.officeLocation ?? '',
			})
		}
	}, [userDetails, userMetadata, isUserDetailsError, isMetadataError, updateUser])

	const acquireToken = useCallback(
		async (scopes: SilentRequest | RedirectRequest, onSuccess: (token: string) => void, loginHint?: string) => {
			if (!account) return

			try {
				const response = await instance.acquireTokenSilent({
					...scopes,
					account: account,
				})
				onSuccess(response.accessToken)
			} catch (error) {
				console.error('Error acquiring token:', error)
				logAction({
					actionName: 'Token Acquisition Error',
					actionType: 'error',
					actionData: {
						prompt: `Error acquiring token ${error}`
					},
				})
				if (error instanceof InteractionRequiredAuthError) {
					try {
						await instance.handleRedirectPromise()
						await instance.acquireTokenRedirect({
							...scopes,
							loginHint: loginHint,
						})
					} catch (redirectError) {
						logAction({
							actionName: 'Error during redirect',
							actionType: 'error',
							actionData: {
								prompt: `Error during redirect ${redirectError}`
							},
						})
							
					}
				}
			}
		},
		[account, instance]
	)

	const getTokenForGraph = useCallback(async () => {
		acquireToken(
			graphScope,
			(accessToken) => {
				setGraphToken(accessToken)
			},
			account?.username
		)
	}, [acquireToken, account])

	const refreshGraphData = useCallback(() => {
		if (graphToken) {
			refetchUserDetails()
			refetchUserMetadata()
		}
	}, [graphToken, refetchUserDetails, refetchUserMetadata])

	const acquireLoginToken = useCallback(async () => {
		if (!account) {
			secureLocalStorage.clear()
			setIsLoading(false)
			return
		}

		try {
			await acquireToken(
				loginRequest,
				(accessToken) => {
					setAxiosInterceptors(accessToken)
					secureLocalStorage.setItem(SECURE_STORAGE_KEY.JWT, accessToken)
					getTokenForGraph()
					setIsAuthenticated(true)
				},
				account?.username
			)
		} finally {
			setIsLoading(false)
		}
	}, [account, acquireToken, getTokenForGraph, setAxiosInterceptors])

	useEffect(() => {
		if (inProgress === InteractionStatus.None) {
			logAction({
				actionName: 'KN_EA_SIGNIN',
				actionType: 'log',
				actionData: {
					prompt: 'User has signed in for the first time.'
				},
			})
			acquireLoginToken()
		}
	}, [inProgress, acquireLoginToken])

	const login = useCallback(() => {
		if (!isLoading) {
			instance.loginRedirect(loginRequest)
		}
	}, [isLoading, instance])

	return { isAuthenticated, login, isLoading, refreshGraphData }
}
