import request from '@Config/request'

export const getUserLoginConsent = async () => {
	const { data, status, statusText } = await request.get<IUserConsent>('/users/v1/consents?consent_for=login')
	return {
		data,
		status,
		statusText,
	}
}

export const updateUserLoginConsent = async () => {
	const { data, status, statusText } = await request.post<string>(`/users/v1/consents`, {
		consent_for: 'login',
	})
	return {
		data,
		status,
		statusText,
	}
}


export const updateUserProfile = async (payload: IUserProfileUpdateRequest) => {
	const { data, status, statusText } = await request.put<{ msg: string }>(`/users/v1/update-profile`, payload)
	return {
		data,
		status,
		statusText,
	}
}
